/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

function News() {
    return (
        <div
            style={{
                marginBottom: rhythm(2.5),
            }}
        >
            <div style={{ marginTop: rhythm(1) }}></div>
            <h3 id="work-experience">News</h3>
            Our paper with with Bartlett School of Architecture received the emerging technology award at ACADIA conference.
        </div>
    )
}

export default News
